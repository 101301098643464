import { type ApolloQueryResult, useApolloClient } from '@apollo/client';
import { useState } from 'react';

import type { getMedia } from '@app/pages/ProductFinder/__generated__/getMedia';

import videoIconSvg from '../../assets/images/videoIcon.svg';
import GET_MEDIA_ITEM_QUERY from '../../pages/ProductFinder/getMedia.query';
import type { VideoOverlayActions } from '../../redux/actions/videoOverlay.actions';
import MediaIcon from '../MediaIcon';

export interface VideoOverlayTriggerProps {
  videoId: string;
  dispatchers: VideoOverlayActions;
}

function VideoOverlayTrigger({
  videoId,
  dispatchers,
}: VideoOverlayTriggerProps) {
  const client = useApolloClient();
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(false);
  const {
    fetchVideoLoading,
    fetchVideoSuccess,
    openVideoOverlay,
    setVideoId,
    setVideoError,
  } = dispatchers;

  const getMedia = async (id: string): Promise<ApolloQueryResult<getMedia>> =>
    client.query({
      query: GET_MEDIA_ITEM_QUERY,
      variables: { id: Number.parseInt(id, 10) },
    });

  const onVideoIconClick = async (): Promise<void> => {
    try {
      setIsVideoLoading(true);
      fetchVideoLoading();
      const queryResult = await getMedia(videoId);
      const chapters = queryResult.data?.media?.chapters?.[0];
      const youTubeVideoId =
        chapters?.__typename === 'YouTubeChapter' && chapters?.videoId;

      if (typeof youTubeVideoId !== 'string') {
        throw new Error(`Invalid YouTube video ID received ${youTubeVideoId}`);
      }

      fetchVideoSuccess();
      setVideoId(youTubeVideoId);
      openVideoOverlay();
      setIsVideoLoading(false);
    } catch (err) {
      setIsVideoLoading(false);

      if (err instanceof Error) {
        setVideoError(`${err.name}: ${err.message}`);
      }
    }
  };

  return (
    <MediaIcon
      isLoading={isVideoLoading}
      onClick={onVideoIconClick}
      svg={videoIconSvg}
      altText="play video"
    />
  );
}

export default VideoOverlayTrigger;
