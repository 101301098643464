import { createActions } from 'redux-actions';

export const OPEN_VIDEO_OVERLAY = 'OPEN_VIDEO_OVERLAY';
export const CLOSE_VIDEO_OVERLAY = 'CLOSE_VIDEO_OVERLAY';
export const FETCH_VIDEO_LOADING = 'FETCH_VIDEO_LOADING';
export const FETCH_VIDEO_SUCCESS = 'FETCH_VIDEO_SUCCESS';
export const SET_VIDEO_ERROR = 'SET_VIDEO_ERROR';
export const SET_VIDEO_ID = 'SET_VIDEO_ID';

export interface VideoOverlayActions {
  openVideoOverlay: () => void;
  closeVideoOverlay: () => void;
  fetchVideoLoading: () => void;
  fetchVideoSuccess: () => void;
  setVideoError: (err: string) => void;
  setVideoId: (payload: string) => void;
}

export const {
  openVideoOverlay,
  closeVideoOverlay,
  fetchVideoLoading,
  fetchVideoSuccess,
  setVideoError,
  setVideoId,
}: VideoOverlayActions = createActions({
  OPEN_VIDEO_OVERLAY: undefined,
  CLOSE_VIDEO_OVERLAY: undefined,
  FETCH_VIDEO_LOADING: undefined,
  FETCH_VIDEO_SUCCESS: undefined,
  SET_VIDEO_ERROR: (err) => err,
  SET_VIDEO_ID: (payload) => payload,
});

export default {
  openVideoOverlay,
  closeVideoOverlay,
  fetchVideoLoading,
  fetchVideoSuccess,
  setVideoError,
  setVideoId,
};
