import type * as Types from '@__graphql__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserLicensesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserLicensesQuery = { userV2?: { __typename: 'AnonymousUser' } | { __typename: 'AuthenticatedUser', licenses_ids?: Array<string> | null } | null };


export const UserLicensesDocument = gql`
    query UserLicenses {
  userV2 {
    __typename
    ... on AuthenticatedUser {
      licenses_ids
    }
  }
}
    `;

/**
 * __useUserLicensesQuery__
 *
 * To run a query within a React component, call `useUserLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLicensesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLicensesQuery(baseOptions?: Apollo.QueryHookOptions<UserLicensesQuery, UserLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLicensesQuery, UserLicensesQueryVariables>(UserLicensesDocument, options);
      }
export function useUserLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLicensesQuery, UserLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLicensesQuery, UserLicensesQueryVariables>(UserLicensesDocument, options);
        }
export type UserLicensesQueryHookResult = ReturnType<typeof useUserLicensesQuery>;
export type UserLicensesLazyQueryHookResult = ReturnType<typeof useUserLicensesLazyQuery>;
export type UserLicensesQueryResult = Apollo.QueryResult<UserLicensesQuery, UserLicensesQueryVariables>;