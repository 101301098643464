import type * as Types from '@__graphql__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PriceFragmentFragment = { minPrice?: string | null, originalPrice?: string | null, itemType?: string | null, license?: string | null, licenseId?: string | null, personalized?: boolean | null, savePrice?: string | null };

export type AttributeFragmentFragment = { key?: string | null, value?: any | null };

export type ProductFragmentFragment = { __typename: 'FinderProduct', id?: string | null, title?: string | null, sku?: string | null, image?: string | null, audioId?: string | null, videoId?: string | null, quickAddToCart?: boolean | null, quickAddToCartSku?: string | null, price?: { main?: PriceFragmentFragment | null, min?: PriceFragmentFragment | null, bundle?: { originalPrice?: string | null, diffPrice?: string | null, minPrice?: string | null } | null } | null, attributes?: Array<AttributeFragmentFragment | null> | null };

export type GetProductsForFinderQueryVariables = Types.Exact<{
  condition?: Types.InputMaybe<Types.Condition>;
  sortBy?: Types.InputMaybe<Types.SortBy>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  language?: Types.InputMaybe<Types.Scalars['Language']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  hitsPerPage?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetProductsForFinderQuery = { getProducts: { result: { __typename: 'ProductSearchPayload', pageInfo: { currentPage?: number | null, hasNextPage?: boolean | null }, items: Array<ProductFragmentFragment> } } };

export const PriceFragmentFragmentDoc = gql`
    fragment priceFragment on Price {
  minPrice
  originalPrice
  itemType
  license
  licenseId
  personalized
  savePrice
}
    `;
export const AttributeFragmentFragmentDoc = gql`
    fragment AttributeFragment on Attribute {
  key
  value
}
    `;
export const ProductFragmentFragmentDoc = gql`
    fragment ProductFragment on FinderProduct {
  id
  title
  sku
  image
  audioId
  videoId
  quickAddToCart
  quickAddToCartSku
  price {
    main {
      ...priceFragment
    }
    min {
      ...priceFragment
    }
    bundle {
      originalPrice
      diffPrice
      minPrice
    }
  }
  attributes(
    keys: ["sticker", "description", "detail_page", "pricing_page", "image_lazy", "override_price", "custom_price_text", "free"]
  ) {
    ...AttributeFragment
  }
  __typename
}
    ${PriceFragmentFragmentDoc}
${AttributeFragmentFragmentDoc}`;
export const GetProductsForFinderDocument = gql`
    query GetProductsForFinder($condition: Condition, $sortBy: SortBy, $search: String, $language: Language, $page: Int, $hitsPerPage: Int) {
  getProducts(
    language: $language
    condition: $condition
    sortBy: $sortBy
    search: $search
    page: $page
    hitsPerPage: $hitsPerPage
  ) {
    result {
      pageInfo {
        currentPage
        hasNextPage
      }
      items {
        ...ProductFragment
      }
      __typename
    }
  }
}
    ${ProductFragmentFragmentDoc}`;

/**
 * __useGetProductsForFinderQuery__
 *
 * To run a query within a React component, call `useGetProductsForFinderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForFinderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForFinderQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *      language: // value for 'language'
 *      page: // value for 'page'
 *      hitsPerPage: // value for 'hitsPerPage'
 *   },
 * });
 */
export function useGetProductsForFinderQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsForFinderQuery, GetProductsForFinderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsForFinderQuery, GetProductsForFinderQueryVariables>(GetProductsForFinderDocument, options);
      }
export function useGetProductsForFinderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsForFinderQuery, GetProductsForFinderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsForFinderQuery, GetProductsForFinderQueryVariables>(GetProductsForFinderDocument, options);
        }
export type GetProductsForFinderQueryHookResult = ReturnType<typeof useGetProductsForFinderQuery>;
export type GetProductsForFinderLazyQueryHookResult = ReturnType<typeof useGetProductsForFinderLazyQuery>;
export type GetProductsForFinderQueryResult = Apollo.QueryResult<GetProductsForFinderQuery, GetProductsForFinderQueryVariables>;